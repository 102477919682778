<template>
  <div class="landing">
    <!-- Navbar -->
    <nav class="nav">
      <div class="left">
        <ul class="menu">
          <li>
            <a href=".section2" data-scroll>SERVICIOS</a>
          </li>
          <li>
            <a href=".section-tracking" data-scroll>TRACKING</a>
          </li>
        </ul>
      </div>
      <div class="center">
        <span>SUPER EXPRESS</span>
        <img src="@/assets/img/logo.svg" />
        <span>MONTERREY PACÍFICO</span>
      </div>
      <div class="right">
        <a href="#" class="menu-mobile" @click.prevent="showMenuMob">
          <i class="fas fa-bars"></i>
        </a>
        <ul class="menu">
          <li>
            <a href=".info-content" data-scroll>RUTAS</a>
          </li>
          <li>
            <a href=".section3" data-scroll>CONTACTO</a>
          </li>
        </ul>
      </div>
      <div class="bottom-nav">
        <img src="@/assets/img/img-bottomnav.png" />
        ¿Cuentas con unidad propia?
        <a
          href="https://api.whatsapp.com/send?phone=528115719321&text=Hola!%20me%20interesa%20obtener%20información."
          target="_blank"
        >
          Contactanos!
        </a>
      </div>
    </nav>

    <!-- Nav mobile -->
    <nav class="nav-mobile">
      <a href="#" class="close-menu" @click.prevent="closeMenuMob">
        <i class="fas fa-times"></i>
      </a>
      <div class="nav-header">
        <img src="@/assets/img/logo.svg" /> <br />
        <span>SUPER EXPRESS MONTERREY PACÍFICO</span> <br />
        <div id="redes-sociales">
          <a
            href="https://www.facebook.com/Super-Express-Monterrey-Pacifico-106894601585534/"
            target="_blank"
          >
            <img src="@/assets/icons/facebook.png" />
          </a>
          <a href="#">
            <img src="@/assets/icons/linkedin.png" />
          </a>
          <a
            href="https://instagram.com/jfesuperexpress?utm_medium=copy_link"
            target="_blank"
          >
            <img src="@/assets/icons/instagram.png" />
          </a>
        </div>
      </div>
      <div class="nav-body">
        <ul>
          <li @click="closeMenuMob">
            <a
              href=".section2"
              data-scroll
              data-scroll-options='{"offset": -250}'
            >
              Servicios
            </a>
          </li>
          <li @click="closeMenuMob">
            <a
              href=".section-tracking"
              data-scroll
              data-scroll-options='{"offset": -100}'
            >
              Tracking
            </a>
          </li>
          <li @click="closeMenuMob">
            <a
              href=".info-content"
              data-scroll
              data-scroll-options='{"offset": -120}'
            >
              Rutas
            </a>
          </li>
          <li @click="closeMenuMob">
            <a
              href=".section3"
              data-scroll
              data-scroll-options='{"offset": -140}'
            >
              Contacto
            </a>
          </li>
        </ul>
      </div>
      <div class="nav-footer">
        <button>Cotiza con nosotros <i class="fab fa-whatsapp"></i></button>
      </div>
    </nav>

    <!-- Fixed info -->
    <a
      href="https://api.whatsapp.com/send?phone=528115719321&text=Hola!%20me%20interesa%20obtener%20información."
      target="_blank"
      class="fixed-info"
    >
      <div class="content">
        <img src="@/assets/img/info.png" />
        <div class="msg">
          <h2>¿Cuentas con unidad propia?</h2>
          <h2>Contáctanos</h2>
        </div>
      </div>
    </a>

    <!-- Content -->
    <div class="content">
      <!-- hero banner -->
      <div class="hero">
        <div class="info">
          <h1>FLETES Y TRANSPORTES</h1>
          <div class="animated">
            <span>
              UNIDAD 5TA RUEDA
              <img src="@/assets/icons/camion3.png" /> PLATAFORMA
              <img src="@/assets/icons/camion1.png" /> JAULA
              <img src="@/assets/icons/caja.png" /> & CAJA SECA
              <img src="@/assets/icons/camion2.png" />
            </span>
          </div>
          <div class="numeros">
            <span>Whatsap +52 (81) 15719321</span>
            <span>Tels +52 (81) 83798680</span>
            <span>+52 (81) 83772552</span>
          </div>
        </div>
      </div>
      <!-- Section 1 -->
      <div class="section1">
        <div class="oferta-valor">
          <h2>OFERTA DE VALOR</h2>
          <span>
            Costos competitivos, disponibilidad de equipos, <br />
            servicios puerta a puerta, así como fletes locales y <br />
            nacionales
          </span>
        </div>
        <div class="quienes-somos">
          <img src="@/assets/img/img-label.png" class="label-img" />
          <h2>¿QUIENES SOMOS?</h2>
          <span>
            Somos una empresa con más de 36 años con sede <br />
            en la ciudad de Monterrey, Nuevo León, Mexico. <br />
            Dedicada al transporte, comprometida con el servicio, <br />
            rapidez, calidad, confianza en el manejo, traslado y <br />
            entrega de sus productos.
          </span>
        </div>
        <div class="element-one"></div>
      </div>

      <!-- Section 2 -->
      <div class="section2">
        <img class="servicios-logo" src="@/assets/img/servicios.png" />

        <div class="servicios-content">
          <img class="circle1" src="@/assets/img/circle1.png" />
          <img class="circle2" src="@/assets/img/circle2.png" />
          <img class="circle3" src="@/assets/img/circle3.png" />
          <img class="circle4" src="@/assets/img/circle4.png" />
        </div>

        <div class="info-content">
          <div class="title-info">
            <h1>CIUDADES DE OPERACIÓN</h1>
          </div>
          <div class="body-info">
            <div class="animated">
              <span>MONTERREY, NL /// CIUDAD DE MEXICO /// TIJUANA</span>
            </div>
          </div>
          <div class="footer-info">
            <span>SUPER EXPRESS</span>
            <img src="@/assets/img/logo-jfe-white.svg" />
            <span>MONTERREY PACÍFICO</span>
          </div>
        </div>
      </div>

      <!-- Section tracking -->
      <div class="section-tracking">
        <div class="cover"></div>
        <div class="content">
          <h1>Tracking en tiempo real</h1>
          <p>
            Todos nuestros camiones estan totalmente equipados con un sistema de
            GPS, asi nos aseguramos de brindarte seguridad y confianza, ademas,
            podras seguir en tiempo real, la ubicacion exacta donde se encuentra
            tu cargamento.
          </p>
          <a href="https://plataforma.soltrack.com/Login.aspx" target="_blank">
            Rastrear
          </a>
        </div>
      </div>

      <!-- Section 3 -->
      <div class="section3">
        <div class="content">
          <div class="label">
            <img src="@/assets/img/img-label.png" class="label-img" />
            <h1>CONTÁCTANOS</h1>
          </div>

          <form @submit.prevent="submitForm">
            <div class="form-left">
              <div class="input">
                <label>NOMBRE</label>
                <input type="text" v-model="form.nombre" />
              </div>
              <div class="input">
                <label>CORREO</label>
                <input type="email" v-model="form.correo" />
              </div>
              <div class="input">
                <label>TELÉFONO</label>
                <input type="text" v-model="form.telefono" />
              </div>
            </div>
            <div class="form-right">
              <div class="input">
                <label>TIPO DE REMOLQUE</label>
                <input type="text" v-model="form.tRemolque" />
              </div>
              <div class="input">
                <label>DESTINO Y ORIGEN</label>
                <input type="text" v-model="form.destinoOrigen" />
              </div>
              <div class="input">
                <label>TIPO DE CARGA</label>
                <input type="text" v-model="form.tCarga" />
              </div>
              <div class="input">
                <label>FECHA TENTATIVA DE VIAJE</label>
                <input
                  type="date"
                  class="input-date"
                  v-model="form.fechaTentativa"
                />
              </div>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" />
                <a href="./aviso-privacidad.pdf" target="_blank" class="aviso">
                  Aceptar politicas
                </a>
              </label>
            </div>
            <div class="contactanos">
              <a
                href="https://api.whatsapp.com/send?phone=528115719321&text=Hola!%20me%20interesa%20obtener%20información."
                target="_blank"
              >
                COTIZA <img src="@/assets/icons/whatsapp.png" /> +52 811 571
                9321
              </a>
            </div>
            <div class="btn-enviar">
              <button v-if="btnSubmit == 'enviar'">ENVIAR</button>
              <button v-if="btnSubmit == 'enviando'" disabled>ENVIANDO</button>
              <button class="success" v-if="btnSubmit == 'enviado'" disabled>
                ENVIADO
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Section 4 -->
      <div class="section4">
        <div class="clientes-content">
          <img
            src="@/assets/img/nuestros-clientes.png"
            class="label-section4"
          />
          <div class="splide clientes">
            <div class="splide__track">
              <ul class="splide__list">
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/acerocid.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/aceros-del-pacifico.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/acerosali.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/acerotec.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/capsa.gif" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/distribuidora_aceros.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/ferreteria.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/ferrumaja.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/fierroylamina.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/Fortacero-logotipo.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/logo_galvasid.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/logodeltoro.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/malova.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/mapco.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/nacionaldeaceros.svg" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/perfileslm.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/plafimsa.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/promacero.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/refino.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/soluciones.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/gruma.png" />
                </li>
                <li class="splide__slide">
                  <img src="@/assets/img/clientes/hari-masa.png" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Section 5 -->
      <!-- <div class="section5">
        <div class="left">
          <img src="@/assets/img/img-casoexito.png" class="img-cexito-web" />
          <img src="@/assets/img/img-cexito.png" class="img-cexito-mobi" />
        </div>
        <div class="right">
          <div class="content">
            <h1>CASO DE EXITO</h1>
            <h1>NOMBRE DEL CASO</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              <br />
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              <br />
              sectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt
              <br />
              ut laoreet dolore magna aliquamsectetuer adipiscing elit Lorem
              ipsum <br />
              dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
              nibh <br />
              euismod tincidunt ut laoreet dolore magna aliquam sectetuer <br />
              adipiscing elit, sed diam nonummy nibh euismod.
            </p>
          </div>
        </div>
      </div> -->
      <!-- Section 6 -->
      <!-- <div class="section6">
        <img src="@/assets/img/curved-text-bg6.png" class="title-section6" />

        <div class="flota splide">
          <div class="splide__track">
            <ul class="splide__list">
              <li class="splide__slide">
                <img src="@/assets/img/flota/camion1.png" />
              </li>
              <li class="splide__slide">
                <img src="@/assets/img/flota/cascadia-nuevo.png" />
              </li>
              <li class="splide__slide">
                <img src="@/assets/img/flota/camion1.png" />
              </li>
              <li class="splide__slide">
                <img src="@/assets/img/flota/camion1.png" />
              </li>
              <li class="splide__slide">
                <img src="@/assets/img/flota/camion1.png" />
              </li>
            </ul>
          </div>
        </div>
      </div> -->
    </div>

    <!-- Footer -->
    <div class="footer">
      <img src="@/assets/img/logo.svg" id="logo-footer" />

      <div class="direccion">
        <span>Av. Dos 127 Colonia Central de Carga,</span> <br />
        <span>Guadalupe, Nuevo León</span> <br />
        <span>C.P: 67129</span>
      </div>
      <div id="redes-sociales">
        <a
          href="https://www.facebook.com/Super-Express-Monterrey-Pacifico-106894601585534/"
          target="_blank"
        >
          <img src="@/assets/icons/facebook.png" />
        </a>
        <a href="#">
          <img src="@/assets/icons/linkedin.png" />
        </a>
        <a
          href="https://instagram.com/jfesuperexpress?utm_medium=copy_link"
          target="_blank"
        >
          <img src="@/assets/icons/instagram.png" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SweetScroll from "sweet-scroll";
import Splide from "@splidejs/splide";
import Swal from "sweetalert2";
// your-app.scss
import "@sweetalert2/themes/bulma/bulma.scss";

export default {
  data() {
    return {
      counterSlider: 0,
      patch_iframe: "",
      btnSubmit: "enviar",
      form: {
        nombre: "",
        correo: "",
        telefono: "",
        tRemolque: "",
        destinoOrigen: "",
        tCarga: "",
        fechaTentativa: "",
      },
    };
  },
  mounted() {
    window.onscroll = function () {
      const nav = document.querySelector(".nav");

      if (window.scrollY >= 194) {
        nav.classList.add("nav-fixed");
        nav.classList.add("animate__animated", "animate__slideInDown");
      } else {
        nav.classList.remove("nav-fixed");
        nav.classList.remove("animate__animated", "animate__slideInDown");
      }
    };

    // splide flota
    // new Splide(".flota", { type: "fade" }).mount();

    // splide clients
    new Splide(".clientes", {
      type: "loop",
      perPage: 3,
      autoplay: true,
    }).mount();

    // https://github.com/tsuyoshiwada/sweet-scroll
    document.addEventListener(
      "DOMContentLoaded",
      () => {
        new SweetScroll({
          /* some options */
          // offset: -150,
          duration: 300,
        });
      },
      false
    );
  },
  methods: {
    async submitForm() {
      const validateFields = await this.valdidateFields();

      if (!validateFields) {
        this.btnSubmit = "enviando";
        await axios.post("php/form.php", this.form);
        this.btnSubmit = "enviado";

        Object.keys(this.form).forEach((key) => {
          return (this.form[key] = "");
        });

        Swal.fire({
          type: "succes",
          title: "Gracias!",
          text: "Nos contactaremos pronto con usted, para brindarle mas informacion.",
          icon: "success",
          confirmButtonText: "Cerrar",
        });

        setTimeout(() => {
          this.btnSubmit = "enviar";
        }, 1000);
      } else {
        Swal.fire({
          type: "error",
          title: "Atencion!",
          text: "Por favor, complete los campos solicitados en el formulario antes de enviar la informacion.",
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      }
    },
    async valdidateFields() {
      return await Object.values(this.form).some((v) => v == "");
    },
    showMenuMob() {
      const menu = document.querySelector(".nav-mobile");
      const body = document.querySelector("body");

      body.style.overflow = "hidden";
      menu.classList.add("animate__animated", "animate__slideInRight");
      menu.style.visibility = "visible";

      setTimeout(() => {
        menu.classList.remove("animate__animated", "animate__slideInRight");
      }, 1000);
    },
    closeMenuMob() {
      const menu = document.querySelector(".nav-mobile");
      const body = document.querySelector("body");

      body.style.overflow = "auto";
      menu.classList.add("animate__animated", "animate__slideOutRight");

      setTimeout(() => {
        menu.classList.remove("animate__animated", "animate__slideOutRight");
        menu.style.visibility = "hidden";
      }, 1000);
    },
  },
};
</script>