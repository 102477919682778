import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";

//TODO: axios config
axios.defaults.withCredentials = true;
// axios.defaults.baseURL = process.env.VUE_APP_AXIOS_PATH;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
